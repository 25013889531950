// src/components/PublicView.js
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { fetchVenueItems, fetchMenuItems } from '../services/firebaseService';
import MenuModal from '../components/MenuModal';
import {
  FaRobot,
  FaUtensils,
  FaBell,
  FaStar,
  FaChevronLeft,
  FaChevronRight,
} from 'react-icons/fa';
import { AuthContext } from '../AuthContext';
import useResizeObserver from '../hooks/useResizeObserver';
import './PublicView.css';

const PublicView = ({ searchQuery = '', priceFilter = 'All' }) => {
  const [venueItems, setVenueItems] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredSpecialMenuItems, setFilteredSpecialMenuItems] = useState([]);
  const [filteredAllMenus, setFilteredAllMenus] = useState([]);
  const [filteredEventItems, setFilteredEventItems] = useState([]);
  const [allVenues, setAllVenues] = useState([]);
  const [newToSpenu, setNewToSpenu] = useState([]);
  const [showSpecialMenuItemsScrollButtons, setShowSpecialMenuItemsScrollButtons] = useState(false);
  const [showAllMenusScrollButtons, setShowAllMenusScrollButtons] = useState(false);
  const [showEventItemsScrollButtons, setShowEventItemsScrollButtons] = useState(false);
  const [showAllVenuesScrollButtons, setShowAllVenuesScrollButtons] = useState(false);
  const [showNewToSpenuScrollButtons, setShowNewToSpenuScrollButtons] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);
  const [modalTitles, setModalTitles] = useState([]); 
  const [modalVenueIds, setModalVenueIds] = useState([]); // New state for venue IDs
  const [isLoadingSpecialMenus, setIsLoadingSpecialMenus] = useState(true);
  const [isLoadingAllMenus, setIsLoadingAllMenus] = useState(true);
  const [isLoadingVenues, setIsLoadingVenues] = useState(true);
  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [isLoadingFeaturedVenues, setIsLoadingFeaturedVenues] = useState(true);

  const navigate = useNavigate();

  const specialMenuItemsRowRef = useRef(null);
  const allMenusRowRef = useRef(null);
  const eventItemsRowRef = useRef(null);
  const allVenuesRowRef = useRef(null);
  const newToSpenuRowRef = useRef(null);

  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const venues = await fetchVenueItems();
        const menus = await fetchMenuItems();
        setVenueItems(venues);
        setMenuItems(menus);

        categorizeAndFilter(venues, menus, searchQuery, priceFilter);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoadingSpecialMenus(false);
        setIsLoadingAllMenus(false);
        setIsLoadingVenues(false);
        setIsLoadingEvents(false);
        setIsLoadingFeaturedVenues(false);
      }
    };
    loadItems();
  }, []);

  useEffect(() => {
    categorizeAndFilter(venueItems, menuItems, searchQuery, priceFilter);
  }, [searchQuery, priceFilter, venueItems, menuItems]);

  // Utilize the custom hook for each scrollable row
  useResizeObserver(specialMenuItemsRowRef, () => {
    checkScrollButtons(specialMenuItemsRowRef, setShowSpecialMenuItemsScrollButtons);
  });

  useResizeObserver(allMenusRowRef, () => {
    checkScrollButtons(allMenusRowRef, setShowAllMenusScrollButtons);
  });

  useResizeObserver(eventItemsRowRef, () => {
    checkScrollButtons(eventItemsRowRef, setShowEventItemsScrollButtons);
  });

  useResizeObserver(allVenuesRowRef, () => {
    checkScrollButtons(allVenuesRowRef, setShowAllVenuesScrollButtons);
  });

  useResizeObserver(newToSpenuRowRef, () => {
    checkScrollButtons(newToSpenuRowRef, setShowNewToSpenuScrollButtons);
  });

  const checkScrollButtons = (ref, setShowButtons) => {
    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setShowButtons(scrollWidth > clientWidth);
    }
  };

  const categorizeAndFilter = (venues, menus, query, price) => {
    const searchTerms = query.toLowerCase().split(' ').filter((term) => term.length > 0);

    // Filter venues
    const filteredVenues = venues.filter(
      (venue) =>
        (searchTerms.some(
          (term) =>
            venue.venuename.toLowerCase().includes(term) ||
            venue.cuisine.toLowerCase().includes(term) ||
            extractCity(venue.address).toLowerCase().includes(term)
        ) ||
          searchTerms.length === 0) &&
        (price === 'All' || venue.price === price)
    );

    // Filter menus
    const filteredMenus = menus.filter(
      (menu) =>
        searchTerms.every((term) => menu.extractedText.toLowerCase().includes(term)) ||
        filteredVenues.some((venue) => venue.venueid.trim() === menu.venueid.trim())
    );

    // Get venues that have matching menu items
    const venuesWithMatchingMenus = venues.filter((venue) =>
      filteredMenus.some((menu) => menu.venueid.trim() === venue.venueid.trim())
    );

    // Combine filtered venues with venues that have matching menu items
    const allMatchingVenues = [...new Set([...filteredVenues, ...venuesWithMatchingMenus])];

    // Filter for New To Spenu (active === false) and All Venues (active === true)
    const newToSpenuVenues = allMatchingVenues.filter((venue) => venue.active === false);
    const otherVenues = allMatchingVenues.filter((venue) => venue.active === true);

    // Filter special menu items
    const specialMenuItems = filteredMenus.filter((menu) => menu.menuType === 'Specials');

    // Filter event items
    const eventItems = filteredMenus.filter((menu) => menu.menuType === 'Events');

    // Filter all menus excluding specials and events
    const allMenus = filteredMenus.filter(
      (menu) => menu.menuType !== 'Specials' && menu.menuType !== 'Events'
    );

    setFilteredSpecialMenuItems(specialMenuItems);
    setFilteredAllMenus(allMenus);
    setFilteredEventItems(eventItems);
    setNewToSpenu(newToSpenuVenues);
    setAllVenues(otherVenues);
  };

  const extractCity = (address) => {
    const parts = address.split(',');
    return parts.length > 1 ? parts[1].trim() : '';
  };

  const handleCardClick = (id) => {
    window.open(`/venue/${id}`, '_blank', 'noopener,noreferrer');
  };

  const handleMenuItemClick = (images, index, titles, venueIds) => {
    setModalImages(images);
    setModalCurrentIndex(index);
    setModalTitles(titles);
    setModalVenueIds(venueIds); // Set venue IDs
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImages([]);
    setModalCurrentIndex(0);
    setModalTitles([]);
    setModalVenueIds([]);
  };

  const scrollLeft = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      ref.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const renderVenueCard = (venue) => (
    <div
      key={venue.id}
      className="venue-card"
      onClick={() => handleCardClick(venue.id)}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter') handleCardClick(venue.id);
      }}
    >
      <img
        src={venue.imageDownloadUrl}
        alt={venue.venuename}
        className="venue-image"
        loading="lazy"
      />
      <div className="venue-info">
        <h3 className="venue-name">{venue.venuename}</h3>
        <p className="venue-cuisine-price">
          <span className="venue-cuisine">{venue.cuisine}</span>
          <span className="venue-cuisine">{' - '}</span>
          <span className="venue-price">{venue.price}</span>
        </p>
        <p className="venue-address">{extractCity(venue.address)}</p>
        {venue.promo && <p className="venue-promo">{venue.promo}</p>}
      </div>
    </div>
  );

  const renderMenuItemCard = (item, index, array) => (
    <div
      key={item.id}
      className="menu-item-card"
      onClick={() =>
        handleMenuItemClick(
          array.map((i) => i.imageDownloadUrl),
          index,
          array.map((i) => i.title || 'No title'),
          array.map((i) => i.venueid) // Pass venue IDs
        )
      }
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter')
          handleMenuItemClick(
            array.map((i) => i.imageDownloadUrl),
            index,
            array.map((i) => i.title || 'No title'),
            array.map((i) => i.venueid) // Pass venue IDs
          );
      }}
    >
      <img
        src={item.imageDownloadUrl}
        alt={item.title}
        className="menu-item-image"
        onError={(e) => {
          console.error(`Error loading image for ${item.title}:`, e);
          e.target.src = '/images/fallback-menu.jpg';
        }}
        loading="lazy"
      />
      <div className="menu-item-info">
        <h3 className="menu-item-name">{item.title || 'No title'}</h3>
      </div>
    </div>
  );

  const renderFeatureCard = (icon, title, description) => (
    <div className="feature-card" key={title}>
      <div className="feature-icon">{icon}</div>
      <h3 className="feature-title">{title}</h3>
      <p className="feature-description">{description}</p>
    </div>
  );

  const features = [
    {
      icon: <FaRobot size={40} />,
      title: 'AI-Powered',
      description: 'Search for specific dishes, your favourite cocktail, or multiple cuisines.',
    },
    {
      icon: <FaUtensils size={40} />,
      title: 'Every Menu',
      description: 'Browse Specials, Lunch, Dinner, Drinks menus and more, all in one place.',
    },
    {
      icon: <FaStar size={40} />,
      title: 'Discover',
      description: 'Instant access to the latest menus as soon as they are available.',
    },
  ];

  const VenueCardSkeleton = () => (
    <div className="venue-card skeleton">
      <div className="skeleton-image"></div>
      <div className="skeleton-text"></div>
      <div className="skeleton-text short"></div>
    </div>
  );

  const MenuItemCardSkeleton = () => (
    <div className="menu-item-card skeleton">
      <div className="skeleton-image"></div>
      <div className="skeleton-text"></div>
    </div>
  );

  return (
    <div className="public-view">
      {/* Special Menus Section */}
      {isLoadingSpecialMenus ? (
        <section className="venue-section specials-section">
          <div className="section-header">
            <h2>Special Menus</h2>
          </div>
          <div className="venue-row-container">
            <div className="venue-row" ref={specialMenuItemsRowRef}>
              {[...Array(4)].map((_, i) => (
                <MenuItemCardSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        filteredSpecialMenuItems.length > 0 && (
          <section className="venue-section specials-section">
            <div className="section-header">
              <h2>Special Menus</h2>
            </div>
            <div className="venue-row-container">
              {showSpecialMenuItemsScrollButtons && (
                <>
                  <button
                    className="scroll-button left"
                    onClick={() => scrollLeft(specialMenuItemsRowRef)}
                    aria-label="Scroll Left"
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="scroll-button right"
                    onClick={() => scrollRight(specialMenuItemsRowRef)}
                    aria-label="Scroll Right"
                  >
                    <FaChevronRight />
                  </button>
                </>
              )}
              <div className="venue-row" ref={specialMenuItemsRowRef}>
                {filteredSpecialMenuItems.map((item, index, array) =>
                  renderMenuItemCard(item, index, array)
                )}
              </div>
            </div>
          </section>
        )
      )}

      {/* All Menus Section */}
      {isLoadingAllMenus ? (
        <section className="venue-section all-menus-section">
          <div className="section-header">
            <h2>All Menus</h2>
          </div>
          <div className="venue-row-container">
            <div className="venue-row" ref={allMenusRowRef}>
              {[...Array(4)].map((_, i) => (
                <MenuItemCardSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        filteredAllMenus.length > 0 && (
          <section className="venue-section all-menus-section">
            <div className="section-header">
              <h2>All Menus</h2>
            </div>
            <div className="venue-row-container">
              {showAllMenusScrollButtons && (
                <>
                  <button
                    className="scroll-button left"
                    onClick={() => scrollLeft(allMenusRowRef)}
                    aria-label="Scroll Left"
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="scroll-button right"
                    onClick={() => scrollRight(allMenusRowRef)}
                    aria-label="Scroll Right"
                  >
                    <FaChevronRight />
                  </button>
                </>
              )}
              <div className="venue-row" ref={allMenusRowRef}>
                {filteredAllMenus.map((item, index, array) =>
                  renderMenuItemCard(item, index, array)
                )}
              </div>
            </div>
          </section>
        )
      )}

      {/* Featured Venues Section */}
      {isLoadingFeaturedVenues ? (
        <section className="venue-section">
          <div className="section-header">
            <h2>Featured Venues</h2>
          </div>
          <div className="venue-row-container">
            <div className="venue-row" ref={newToSpenuRowRef}>
              {[...Array(4)].map((_, i) => (
                <VenueCardSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        newToSpenu.length > 0 && (
          <section className="venue-section">
            <div className="section-header">
              <h2>Featured Venues</h2>
            </div>
            <div className="venue-row-container">
              {showNewToSpenuScrollButtons && (
                <>
                  <button
                    className="scroll-button left"
                    onClick={() => scrollLeft(newToSpenuRowRef)}
                    aria-label="Scroll Left"
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="scroll-button right"
                    onClick={() => scrollRight(newToSpenuRowRef)}
                    aria-label="Scroll Right"
                  >
                    <FaChevronRight />
                  </button>
                </>
              )}
              <div className="venue-row" ref={newToSpenuRowRef}>
                {newToSpenu.map(renderVenueCard)}
              </div>
            </div>
          </section>
        )
      )}

      {/* All Venues Section */}
      {isLoadingVenues ? (
        <section className="venue-section">
          <div className="section-header">
            <h2>All Venues</h2>
          </div>
          <div className="venue-row-container">
            <div className="venue-row" ref={allVenuesRowRef}>
              {[...Array(4)].map((_, i) => (
                <VenueCardSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        allVenues.length > 0 && (
          <section className="venue-section">
            <div className="section-header">
              <h2>All Venues</h2>
            </div>
            <div className="venue-row-container">
              {showAllVenuesScrollButtons && (
                <>
                  <button
                    className="scroll-button left"
                    onClick={() => scrollLeft(allVenuesRowRef)}
                    aria-label="Scroll Left"
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="scroll-button right"
                    onClick={() => scrollRight(allVenuesRowRef)}
                    aria-label="Scroll Right"
                  >
                    <FaChevronRight />
                  </button>
                </>
              )}
              <div className="venue-row" ref={allVenuesRowRef}>
                {allVenues.map(renderVenueCard)}
              </div>
            </div>
          </section>
        )
      )}

      {/* Events Section */}
      {isLoadingEvents ? (
        <section className="venue-section">
          <div className="section-header">
            <h2>Events</h2>
          </div>
          <div className="venue-row-container">
            <div className="venue-row" ref={eventItemsRowRef}>
              {[...Array(4)].map((_, i) => (
                <MenuItemCardSkeleton key={i} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        filteredEventItems.length > 0 && (
          <section className="venue-section">
            <div className="section-header">
              <h2>Events</h2>
            </div>
            <div className="venue-row-container">
              {showEventItemsScrollButtons && (
                <>
                  <button
                    className="scroll-button left"
                    onClick={() => scrollLeft(eventItemsRowRef)}
                    aria-label="Scroll Left"
                  >
                    <FaChevronLeft />
                  </button>
                  <button
                    className="scroll-button right"
                    onClick={() => scrollRight(eventItemsRowRef)}
                    aria-label="Scroll Right"
                  >
                    <FaChevronRight />
                  </button>
                </>
              )}
              <div className="venue-row" ref={eventItemsRowRef}>
                {filteredEventItems.map((item, index, array) =>
                  renderMenuItemCard(item, index, array)
                )}
              </div>
            </div>
          </section>
        )
      )}

      {/* Features Section */}
      <section className="features-section">
        <div className="section-header">
          <h2>What is Spenu?</h2>
        </div>
        <div className="features-row">
          {features.map((feature) =>
            renderFeatureCard(feature.icon, feature.title, feature.description)
          )}
        </div>
      </section>

      {/* Conditionally Render Fixed Bottom Bar Only When User is Not Logged In */}
      {!currentUser && (
        <div className="fixed-bottom-bar">
          <Link to="/signup" className="fixed-bar-login-button sign-up-button">
            Sign Up
          </Link>
          <span className="fixed-bar-text">
            Get the Daily Specials and Personalized Recommendations by creating a Spenu account
          </span>
        </div>
      )}

      {/* Menu Modal */}
      {isModalOpen && (
        <MenuModal 
          images={modalImages} 
          currentIndex={modalCurrentIndex} 
          onClose={closeModal} 
          titles={modalTitles} 
          venueIds={modalVenueIds} // Pass venue IDs
        />
      )}
    </div>
  );
};

export default PublicView;
