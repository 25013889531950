// src/pages/VenueDetail.jsx

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchVenueItems, fetchMenuItemsByVenue } from '../services/firebaseService';
import MenuModal from '../components/MenuModal';
import StaticMenuModal from '../components/StaticMenuModal';
import VenueDetailSkeleton from '../components/VenueDetailSkeleton'; // Import the skeleton
import './VenueDetail.css';

const VenueDetail = () => {
  const { id } = useParams();
  const [venue, setVenue] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState('');

  // **New Loading States**
  const [isLoadingVenue, setIsLoadingVenue] = useState(true);
  const [isLoadingMenu, setIsLoadingMenu] = useState(true);

  useEffect(() => {
    const loadVenue = async () => {
      try {
        setIsLoadingVenue(true);
        setIsLoadingMenu(true);

        const items = await fetchVenueItems();
        const selectedVenue = items.find(item => item.id === id);
        setVenue(selectedVenue);

        if (selectedVenue) {
          document.title = selectedVenue.venuename;
          const menus = await fetchMenuItemsByVenue(selectedVenue.venueid);
          setMenuItems(menus);
          const specialsMenu = menus.find(menu => menu.menuType === 'Specials');
          const standardMenu = menus.find(menu => menu.menuType === 'Standard');
          setSelectedMenu(specialsMenu || standardMenu || menus[0]);
        }
      } catch (error) {
        console.error('Error fetching venue details:', error);
        // Optionally, set error states here to display error messages to users
      } finally {
        setIsLoadingVenue(false);
        setIsLoadingMenu(false);
      }
    };
    loadVenue();
  }, [id]);

  const filterMenus = (type) => {
    const filteredMenu = menuItems.find(menu => menu.menuType === type);
    setSelectedMenu(filteredMenu);
  };

  const hasMenuType = (type) => {
    return menuItems.some(menu => menu.menuType === type);
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const handleMenuImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImageUrl('');
  };

  if (isLoadingVenue || isLoadingMenu) {
    return <VenueDetailSkeleton />;
  }

  if (!venue) return <p>Venue not found.</p>;

  return (
    <div className="venue-detail">
      <img src={venue.imageDownloadUrl} alt={venue.venuename} className="venue-detail-image" loading="lazy" />
      <div className="venue-detail-content">
        <div className="left-column">
          <div className="venue-detail-info">
            <h1>{venue.venuename}</h1>
            {venue.promo && <p className="venue-detail-promo">{venue.promo}</p>}
            <p className="venue-desc">
              {isDescriptionExpanded ? venue.description : `${venue.description.substring(0, 300)}...`}
              <span className="read-more-less" onClick={toggleDescription}>
                {isDescriptionExpanded ? ' Read less' : ' Read more'}
              </span>
            </p>
            <h2 className="menus-title">Menus</h2>
          </div>
          <div className="menu-section">
            <div className="menu-buttons-wrapper">
              {['Specials', 'Standard', 'Sunday', 'Drinks', 'Events'].map(type => (
                <button
                  key={type}
                  className={`menu-button ${selectedMenu?.menuType === type ? 'active' : ''}`}
                  onClick={() => filterMenus(type)}
                  disabled={!hasMenuType(type)}
                >
                  {type}
                </button>
              ))}
            </div>
            <div className="menu-buttons-wrapper">
              {menuItems
                .filter(menu => menu.menuType === selectedMenu?.menuType)
                .map(menu => (
                  <button
                    key={menu.id}
                    className={`menu-button ${menu.id === selectedMenu.id ? 'active' : ''}`}
                    onClick={() => setSelectedMenu(menu)}
                  >
                    {menu.description}
                  </button>
                ))}
            </div>
            <div className="menu-and-output">
              {selectedMenu && (
                <>
                  <div className="menu-detail">
                    <img
                      src={selectedMenu.imageDownloadUrl}
                      alt={selectedMenu.title}
                      className="menu-image"
                      onClick={() => handleMenuImageClick(selectedMenu.imageDownloadUrl)}
                    />
                  </div>
                  <div className="menu-output">
                    <p><strong>Menu Summary</strong></p>
                    <p>{selectedMenu.output}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="right-column">
          <div className="venue-detail-map">
            <iframe
              src={`https://www.google.com/maps/embed/v1/view?key=AIzaSyATfjXiFc5FHpHQu63uWGzSEPKcAQ9Ntus&center=${venue.latitude},${venue.longitude}&zoom=15`}
              width="100%"
              height="100%"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              title="Venue Map"
            ></iframe>
          </div>
          <div className="venue-detail-info-below-map">
            <p className="additional-info"><strong>Additional Information</strong></p>
            <p><i className="fas fa-utensils icon"></i><strong>Cuisine:</strong> {venue.cuisine}</p>
            <p><i className="fas fa-credit-card icon"></i><strong>Payment:</strong> {venue.payment}</p>
            <p><i className="fas fa-tag icon"></i><strong>Price Range:</strong> {venue.price}</p>
            <p><i className="fas fa-phone icon"></i><strong>Telephone:</strong> {venue.telephone}</p>
            <p><i className="fas fa-map-marker-alt icon"></i><strong>Address:</strong> {venue.address}</p>
            <p><i className="fas fa-globe icon"></i>
              <strong>Website:</strong>  <a href={venue.website} target="_blank" rel="noopener noreferrer">
                {venue.website.replace(/^https?:\/\//, '')}
              </a>
            </p>          
          </div>
        </div>
      </div>
      {isModalOpen && (
        <StaticMenuModal 
          imageUrl={modalImageUrl}
          title={selectedMenu?.title}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default VenueDetail;