// src/components/Preferences.js
import React, { useState, useEffect, useRef } from 'react';
import './Preferences.css';
import { signOut } from 'firebase/auth';
import { auth } from '../firebase-config';
import { useNavigate } from 'react-router-dom';

const Preferences = ({ fullName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const initials = fullName
    ? fullName
        .split(' ')
        .map(name => name[0])
        .join('')
        .toUpperCase()
    : '';

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/'); // Navigate to home page after logout
  };

  const handleOptionSelect = (option) => {
    setIsOpen(false);
    if (option === 'Logout') {
      handleLogout();
    } else {
      // Handle other options here
      console.log(`Selected option: ${option}`);
    }
  };

  return (
    <div className="preferences-dropdown" ref={dropdownRef}>
      <button className="preferences-toggle" onClick={toggleDropdown}>
        {initials}
      </button>
      {isOpen && (
        <div className="preferences-menu">
          <div className="preferences-item" onClick={() => handleOptionSelect('Profile')}>
            Profile
          </div>
          <div className="preferences-item" onClick={() => handleOptionSelect('Notifications')}>
            Notifications
          </div>
          <div className="preferences-item" onClick={() => handleOptionSelect('Cuisines')}>
            Cuisines
          </div>
          <div className="preferences-item" onClick={() => handleOptionSelect('Logout')}>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};

export default Preferences;
