// src/pages/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <h1>SPENU TERMS OF USE</h1>
        <p>Last updated September 28, 2024</p>
        <p>
          These Spenu Terms of Use (this “Agreement”) apply to your access or use of the Spenu websites, mobile sites, and applications (collectively, the “Spenu Sites”) and the content, features, and services (the “Services”) made available by Spenu.
        </p>
        <p>
          In this Agreement, “Spenu” and “we” mean the Spenu company that is providing the Services to you, and with whom you are entering into this Agreement, which depends on the country in which you reside. The terms “User” and “you” mean any user of the Services. This Agreement incorporates Spenu’s standard policies, procedures, and terms and conditions for use of the Services that are referenced by name or by links in this Agreement (collectively, the “Spenu Policies”).
        </p>
        <p>
          By accessing or using the Services or by clicking “accept” or “agree” to this Agreement, you (1) acknowledge that you have read, understand, and agree to be bound by this Agreement, and (2) represent and warrant that you are of legal age and not prohibited by law from accessing or using the Services.
        </p>        
        <h2>SUMMARY OF KEY POINTS</h2>
        <p>
          This summary provides key points from our terms of use, but you can find out more details by reading the full text in the main body of the document below.
        </p>
        <p>
          <strong>Modifications to the Agreement</strong><br />
          Spenu may update or revise this Agreement (including any Spenu Policies) from time to time. You agree that you will review this Agreement periodically.
        </p>

        <p>
          <strong>Dispute Resolution</strong><br />
          Any disputes arising from the use of Spenu Services will be settled through binding arbitration, with a class action waiver applicable to certain regions.
        </p>

        <h2>TABLE OF CONTENTS</h2>
        <ul>
          <li>Modifications to the Agreement</li>
          <li>Reservation Services</li>
          <li>No-Show Policy</li>
          <li>Spenu Dining Rewards</li>
          <li>Payment, Gift Card, and Other Services</li>
          <li>Privacy Policy</li>
          <li>Your Account</li>
          <li>Communications from Spenu</li>
          <li>Technical Requirements</li>
          <li>Use Restrictions</li>
          <li>Arbitration and Class Action Waiver</li>
          <li>Intellectual Property Rights</li>
          <li>Termination</li>
        </ul>

        <h2>1. MODIFICATIONS TO THE AGREEMENT</h2>
        <p>
          Spenu may update or revise this Agreement from time to time. You agree that you will review this Agreement periodically, and any use of the Services after such updates constitutes acceptance of the modified Agreement.
        </p>

        <h2>2. SPENU DINING REWARDS</h2>
        <p>
          Depending on your region, you may participate in Spenu’s Dining Rewards program. Participation is subject to the program’s terms and conditions.
        </p>

        <h2>3. PAYMENT, GIFT CARD, AND OTHER SERVICES</h2>
        <p>
          Depending on your country of residency, Spenu may offer additional services such as payment services, gift cards, and others, subject to further terms.
        </p>

        <h2>4. PRIVACY POLICY</h2>
        <p>
          Spenu is committed to safeguarding your privacy. Please review our Privacy Policy for details on how we collect, use, and disclose your information in connection with the Services.
        </p>

        <h2>5. YOUR ACCOUNT</h2>
        <p>
          You may (but are not required to) create an account with Spenu to use certain services. It is your responsibility to maintain the confidentiality of your account, and you are solely responsible for all activities under your account.
        </p>

        <h2>6. COMMUNICATIONS FROM SPENU</h2>
        <p>
          Spenu may communicate with you via electronic messages, including email, text, or push notifications, in accordance with its Privacy Policy.
        </p>

        <h2>7. TECHNICAL REQUIREMENTS</h2>
        <p>
          Using Spenu’s Services requires Internet access through your device. You are responsible for data charges and ensuring that your device is compatible with our Services.
        </p>

        <h2>8. USE RESTRICTIONS</h2>
        <p>
          The Services are provided for personal use only. You agree not to deep-link, scrape, or use automated tools to access or monitor the Services.
        </p>

        <h2>9. INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          Spenu retains all rights in the content, trademarks, and other intellectual property on the Services. You are granted a limited license to use the Services for personal purposes.
        </p>

        <h2>10. TERMINATION</h2>
        <p>
          Spenu may terminate your access to the Services at its sole discretion, without notice, for any reason, including breach of this Agreement.
        </p>

        <h2>11. ARBITRATION AND CLASS ACTION WAIVER</h2>
        <p>
          Any disputes related to this Agreement will be resolved through binding arbitration, and you waive any rights to participate in a class action. Arbitration will be conducted in San Francisco, California, unless otherwise agreed upon.
        </p>

        <h2>12. MODIFICATIONS TO SERVICES</h2>
        <p>
          Spenu reserves the right to modify or discontinue any part of its Services at any time without notice or liability to you.
        </p>

        <h2>13. UNITED STATES GOVERNMENT END USERS</h2>
        <p>
          The Services are commercial items, and as such, are provided with only those rights as are granted to all other users under this Agreement.
        </p>

        <h2>14. EXPORT CONTROL</h2>
        <p>
          You may not use, export, or re-export the Services in violation of any applicable laws, including United States export laws.
        </p>

        <h2>15. RELEASE</h2>
        <p>
          Restaurants and Merchants are solely responsible for their interactions with you. You release Spenu from any claims arising from such interactions.
        </p>

        <h2>16. TECHNICAL LIMITATIONS</h2>
        <p>
          Spenu makes no guarantees regarding the availability or uptime of the Services, and we are not liable for any interruptions or errors.
        </p>

        <h2>17. SEVERABILITY</h2>
        <p>
          If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will remain in effect.
        </p>

        <h2>18. ASSIGNMENT</h2>
        <p>
          You may not assign or transfer your rights under this Agreement, but Spenu may freely assign its rights without restriction.
        </p>

        <h2>19. WAIVER</h2>
        <p>
          The failure to enforce any provision of this Agreement shall not be deemed a waiver of that provision.
        </p>

        <h2>20. ENTIRE AGREEMENT</h2>
        <p>
          This Agreement constitutes the entire agreement between you and Spenu regarding the use of the Services.
        </p>
        
        <h2>21. CHOICE OF LAW</h2>
        <p>
          This Agreement is governed by the laws of the United Kingdom, without regard to conflict of law principles. Any disputes will be settled in courts located in United Kingdom.
        </p>

        <h2>22. CONTACT INFORMATION</h2>
        <p>
          For any questions or concerns about this Agreement, you may contact Spenu at their headquarters located in Windsor, UK
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;