// src/pages/MenuPortal.js
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { fetchAllParsedMenuItems } from '../services/firebaseService';
import { useTable } from 'react-table';
import { useNavigate } from 'react-router-dom';
import './MenuPortal.css';

const MenuPortal = () => {
  const { currentUser } = useContext(AuthContext);
  const [menuItems, setMenuItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [searchName, setSearchName] = useState('');
  const [filterMenuType, setFilterMenuType] = useState('');
  const [filterPriceMin, setFilterPriceMin] = useState('');
  const [filterPriceMax, setFilterPriceMax] = useState('');
  const [searchVenueId, setSearchVenueId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const loadMenuItems = async () => {
      try {
        const items = await fetchAllParsedMenuItems();
        setMenuItems(items);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };

    if (currentUser) {
      loadMenuItems();
    } else {
      navigate('/login');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    applyFilters();
  }, [menuItems, searchName, filterMenuType, filterPriceMin, filterPriceMax, searchVenueId]);

  const applyFilters = () => {
    let filteredData = [...menuItems];

    // Filter by Menu Item Name
    if (searchName) {
      filteredData = filteredData.filter((item) =>
        item.name.toLowerCase().includes(searchName.toLowerCase())
      );
    }

    // Filter by Menu Type
    if (filterMenuType) {
      filteredData = filteredData.filter((item) => item.menuType === filterMenuType);
    }

    // Filter by Price Range
    if (filterPriceMin !== '') {
      filteredData = filteredData.filter((item) => item.price >= parseFloat(filterPriceMin));
    }
    if (filterPriceMax !== '') {
      filteredData = filteredData.filter((item) => item.price <= parseFloat(filterPriceMax));
    }

    // Filter by Venue ID
    if (searchVenueId) {
      filteredData = filteredData.filter((item) =>
        item.venueid.toLowerCase().includes(searchVenueId.toLowerCase())
      );
    }

    setFilteredItems(filteredData);
  };

  const resetFilters = () => {
    setSearchName('');
    setFilterMenuType('');
    setFilterPriceMin('');
    setFilterPriceMax('');
    setSearchVenueId('');
  };

  const data = React.useMemo(() => filteredItems, [filteredItems]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Menu Item Name',
        accessor: 'name',
      },
      {
        Header: 'Price (£)',
        accessor: 'price',
      },
      {
        Header: 'Menu Type',
        accessor: 'menuType',
      },
      {
        Header: 'Venue ID',
        accessor: 'venueid',
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => {
          return value ? value.toDate().toLocaleString() : '';
        },
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="menu-portal">
      <h1>Menu Portal</h1>
      <div className="filters">
        <input
          type="text"
          placeholder="Search by Menu Item Name"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <select
          value={filterMenuType}
          onChange={(e) => setFilterMenuType(e.target.value)}
        >
          <option value="">All Menu Types</option>
          <option value="Standard">Standard</option>
          <option value="Specials">Specials</option>
          <option value="Sunday">Sunday</option>
          <option value="Drinks">Drinks</option>
          <option value="Events">Events</option>
        </select>
        <input
          type="number"
          placeholder="Min Price"
          value={filterPriceMin}
          onChange={(e) => setFilterPriceMin(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max Price"
          value={filterPriceMax}
          onChange={(e) => setFilterPriceMax(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by Venue ID"
          value={searchVenueId}
          onChange={(e) => setSearchVenueId(e.target.value)}
        />
        <button onClick={applyFilters}>Apply Filters</button>
        <button onClick={resetFilters}>Reset Filters</button>
      </div>
      <table {...getTableProps()} className="menu-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} key={column.id}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.length === 0 ? (
            <tr>
              <td colSpan={columns.length}>No menu items found.</td>
            </tr>
          ) : (
            rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={row.id}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()} key={cell.column.id}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default MenuPortal;
