// src/components/VenueDetailSkeleton.jsx

import React from 'react';
import './VenueDetailSkeleton.css'; // Create this CSS file for skeleton-specific styles

const VenueDetailSkeleton = () => (
  <div className="venue-detail-card skeleton">
    <div className="venue-detail-image skeleton"></div>
    <div className="venue-detail-info skeleton">
      <div className="skeleton-text" style={{ width: '60%' }}></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text" style={{ width: '40%' }}></div>
    </div>
  </div>
);

export default VenueDetailSkeleton;