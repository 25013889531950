import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { db } from '../firebase-config';
import { doc, setDoc } from 'firebase/firestore';
import imageCompression from 'browser-image-compression';
import './UploadMenuModal.css';
//import '../Styles.css';
//import { updateMenuOutput, generateMenuOutput } from '../services/firebaseService';
import CustomAlert from './CustomAlert';

const UploadMenuModal = ({ show, onClose, venueId, venueName, loadVenueItems }) => {
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);
  const [menuType, setMenuType] = useState('Standard');
  const [image, setImage] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  useEffect(() => {
    if (alertMessage) {
      const timer = setTimeout(() => {
        setAlertMessage('');
        onClose();
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [alertMessage, onClose]);

  const resetFields = () => {
    setDescription('');
    setActive(true);
    setMenuType('Standard');
    setImage(null);
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUploadMenu = async () => {
    if (!description.trim()) {
      setAlertMessage('Description is required.');
      return;
    }
    if (!image) {
      setAlertMessage('Image is required.');
      return;
    }
    if (!venueId) {
      setAlertMessage('Venue is required.');
      return;
    }

    setIsUploading(true);
    console.log('Uploading menu for venueId:', venueId);

    const storage = getStorage();
    const dateString = new Date().toISOString().replace(/[-:T.]/g, '');
    const imageExtension = image.name.split('.').pop();
    const imageName = `${venueId}_${dateString}.${imageExtension}`;
    const storagePath = `menuItems/${imageName}`;
    const storageRef = ref(storage, storagePath);

    try {
      // Compress the image
      const options = {
        maxSizeMB: 0.5, // Increase the maximum file size
        maxWidthOrHeight: 2000, // Adjust dimensions to retain quality
        useWebWorker: true, // Use web worker for performance
        initialQuality: 0.8, // Start with a higher quality
        maxIteration: 10, // Allow more iterations for better results
      };
      const compressedFile = await imageCompression(image, options);

      await uploadBytes(storageRef, compressedFile);
      const gsUrl = `gs://${storage.app.options.storageBucket}/${storagePath}`;

      const newItem = {
        id: imageName,
        title: venueName,
        description,
        venueid: venueId,
        imageURL: gsUrl,
        active,
        menuType
      };

      console.log('New menu item:', newItem);

      await setDoc(doc(db, 'menuItems', imageName), newItem);

      setAlertMessage('Menu uploaded successfully!');

      // Reset fields after successful upload
      resetFields();

      loadVenueItems();
    } catch (error) {
      console.error('Error uploading menu:', error);
      setAlertMessage('Failed to upload menu.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage('');
  };

  if (!show) return null;

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Upload Menu</h2>
        <div className="form-group">
          <label htmlFor="menuType">Menu Type</label>
          <select id="menuType" value={menuType} onChange={(e) => setMenuType(e.target.value)}>
            <option value="Standard">Standard</option>
            <option value="Specials">Specials</option>
            <option value="Sunday">Sunday</option>
            <option value="Drinks">Drinks</option>
            <option value="Events">Events</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description</label>
          <input 
            id="description" 
            type="text" 
            value={description} 
            onChange={(e) => setDescription(e.target.value)} 
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Image</label>
          <input 
            id="image" 
            type="file" 
            accept="image/jpeg, image/png"
            onChange={handleImageChange} 
          />
        </div>
        <button className="upload-button" onClick={handleUploadMenu} disabled={isUploading}>
          {isUploading ? 'Uploading...' : 'Upload Menu'}
        </button>
      </div>
      {isUploading && <div aria-live="polite">Uploading...</div>}
      {alertMessage && <CustomAlert message={alertMessage} onClose={handleCloseAlert} />}
    </div>
  );
};

export default UploadMenuModal;