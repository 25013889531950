// src/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import { monitorAuthState, fetchVenueItems, fetchFoodieData } from './services/firebaseService';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [venueItems, setVenueItems] = useState([]);
  const [foodieData, setFoodieData] = useState(null);

  useEffect(() => {
    const unsubscribe = monitorAuthState(
      async (user) => {
        setCurrentUser(user);
        if (user) {
          const venues = await fetchVenueItems(user.uid);
          setVenueItems(venues);

          // Fetch the user's full name from the "foodies" collection
          const foodie = await fetchFoodieData(user.uid);
          setFoodieData(foodie);
        }
      },
      () => {
        setCurrentUser(null);
        setVenueItems([]);
        setFoodieData(null);
      }
    );

    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, venueItems, foodieData }}>
      {children}
    </AuthContext.Provider>
  );
};
