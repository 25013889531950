// src/components/StaticMenuModal.js
import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import './StaticMenuModal.css';

const StaticMenuModal = ({ imageUrl, title, onClose }) => {
  // Handle keyboard events for closing modal
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  if (!imageUrl) {
    console.error("No image URL provided to StaticMenuModal");
    return null;
  }

  return (
    <div
      className="static-menu-modal-overlay"
      onClick={onClose}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="static-menu-modal-content"
        onClick={(e) => e.stopPropagation()}
        role="document"
      >
        <button 
          className="static-close-button" 
          onClick={onClose} 
          aria-label="Close menu"
        >
          <FaTimes />
        </button>

        {title && <div className="static-menu-title">{title}</div>}
        
        <div className="static-menu-image-container">
          <img 
            src={imageUrl} 
            alt={title || "Menu"} 
            className="static-menu-image" 
          />
        </div>
      </div>
    </div>
  );
};

export default StaticMenuModal;