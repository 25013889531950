// src/pages/AdminPanel.js
import React, { useContext, useEffect, useState } from 'react';
import { VenueContext } from '../contexts/VenueContext';
import AddVenue from '../components/AddVenue';
import './AdminPanel.css';
import { fetchMenuItemsByVenue } from '../services/firebaseService'; // Import the function

const AdminPanel = () => {
  const { venues, users, addVenue } = useContext(VenueContext);
  const [menuItems, setMenuItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadMenuItems = async () => {
      if (!venues || venues.length === 0) {
        console.error("No venues found or failed to fetch venues.");
        setLoading(false);
        return;
      }

      try {
        const menuData = {};
        for (const venue of venues) {
          const items = await fetchMenuItemsByVenue(venue.venueid);
          menuData[venue.venueid] = items;
        }
        setMenuItems(menuData);
      } catch (err) {
        console.error("Error fetching menu items:", err);
        setError("Failed to load menu items.");
      } finally {
        setLoading(false);
      }
    };

    loadMenuItems();
  }, [venues]);

  if (loading) {
    return (
      <div className="admin-panel">
        <h1>Admin Panel</h1>
        <AddVenue />
        <div className="venue-list">
          <h2>Venues</h2>
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="admin-panel">
        <h1>Admin Panel</h1>
        <AddVenue />
        <div className="venue-list">
          <h2>Venues</h2>
          <p className="error">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="admin-panel">
      <h1>Admin Panel</h1>
      <AddVenue />
      <div className="venue-list">
        <h2>Venues</h2>
        {(!venues || venues.length === 0) ? (
          <p>No venues available.</p>
        ) : (
          venues.map(venue => (
            <div key={venue.id} className="venue-item">
              <h3>{venue.venuename}</h3>
              <p>{venue.description}</p>
              <p><strong>Owner:</strong> {venue.email}</p>
              {/* Menu Items */}
              <div className="menu-items">
                <h4>Menu Items</h4>
                {menuItems[venue.venueid]?.length === 0 ? (
                  <p>No menu items available.</p>
                ) : (
                  <ul>
                    {menuItems[venue.venueid]?.map(item => (
                      <li key={item.id}>
                        <img
                          src={item.imageDownloadUrl || '/images/fallback.jpg'}
                          alt={item.title || 'Menu Item Image'}
                          className="menu-image"
                        />
                        <div className="menu-info">
                          <p><strong>{item.title || 'No title'}</strong></p>
                          <p>{item.description || 'No description available.'}</p>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
