// src/components/Footer.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom'; // Using Link from react-router-dom
import { AuthContext } from '../AuthContext';
import { auth } from '../firebase-config';
import { signOut } from 'firebase/auth';
import './Footer.css';

const Footer = () => {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>About Us</h4>
          <p>Spenu, your smart culinary guide.</p>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: <a href="mailto:andy@spenu.co.uk">andy@spenu.co.uk</a></p>
        </div>

        <div className="footer-section">
          <div className="venue-admin-actions">
            {currentUser ? (
              <button onClick={handleLogout} className="footer-sign-in-button">Log out</button>
            ) : (
              <Link to="/login" className="footer-sign-in-button">Venue Log in</Link>
            )}
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-links">
          {/* Use Link for internal navigation */}
          <Link to="/privacy-policy">Privacy Policy</Link>
          <Link to="/termsofuse">Terms of use</Link>
          <a href="#">Cookies and Interest-Based Ads</a>
          <a href="#">Cookie Preferences</a>
        </div>
        <div className="footer-copyright">
          Copyright © 2024 Spenu, UK - All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;