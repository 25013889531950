// src/pages/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { fetchFoodieData } from '../services/firebaseService'; // Import fetchFoodieData
import './Login.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if the user exists in the "foodies" collection
      const foodieData = await fetchFoodieData(user.uid);

      if (foodieData) {
        // If the user is a foodie, navigate to PublicView
        navigate('/public');
      } else if (email === 'admin@spenu.com') {
        // Navigate to the admin panel for admin users
        navigate('/admin');
      } else {
        // Navigate to the venues page for non-admin venue users
        navigate('/venues');
      }
    } catch (error) {
      console.error('Login error:', error);
      setError(error.message);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="container">
      <div className="formContainer">
        <form className="form" onSubmit={handleLogin}>
          <img src={`${process.env.PUBLIC_URL}/spenu.png`} alt="Logo" className="logo" />
          {error && <p className="error">{error}</p>}
          <div>
            <input
              type="email"
              className="input"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <input
              type="password"
              className="input"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="button" type="submit">
            Log In
          </button>
          <button className="forgotButton" type="button" onClick={handleForgotPassword}>
            Forgot Password?
          </button>
        </form>
        <div className="supportText">
          Need help? Email Support <a href="mailto:andy@spenu.co.uk">andy@spenu.co.uk</a>
        </div>
        <div className="betaVersion">BETA Version 1.12</div>
      </div>
    </div>
  );
}

export default Login;
