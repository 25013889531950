// src/components/VenueCardSkeleton.jsx
import React from 'react';
import './VenueCardSkeleton.css'; // We'll define styles separately

const VenueCardSkeleton = () => (
  <div className="venue-all-card skeleton">
    <div className="venue-all-image skeleton"></div>
    <div className="venue-all-info skeleton">
      <div className="skeleton-text"></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text short"></div>
      <div className="skeleton-text short"></div>
    </div>
  </div>
);

export default VenueCardSkeleton;