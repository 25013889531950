// src/components/MenuModal.js
import React, { useState, useEffect } from 'react';
import { FaTimes, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import './MenuModal.css';

const MenuModal = ({ images, currentIndex, onClose, titles }) => {
  const [index, setIndex] = useState(currentIndex);

  // Update index when currentIndex prop changes
  useEffect(() => {
    setIndex(currentIndex);
  }, [currentIndex]);

  // Handle left and right navigation
  const showPrevious = () => {
    setIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const showNext = () => {
    setIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  // Handle keyboard events
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowLeft') {
        showPrevious();
      } else if (e.key === 'ArrowRight') {
        showNext();
      } else if (e.key === 'Escape') {
        onClose();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);

  // Handle swipe gestures
  useEffect(() => {
    let touchStartX = 0;

    const handleTouchStart = (e) => {
      touchStartX = e.changedTouches[0].screenX;
    };

    const handleTouchEnd = (e) => {
      const touchEndX = e.changedTouches[0].screenX;
      if (touchStartX - touchEndX > 50) {
        // Swiped left
        showNext();
      } else if (touchEndX - touchStartX > 50) {
        // Swiped right
        showPrevious();
      }
    };

    const modalElement = document.getElementById('menu-modal-overlay');
    if (modalElement) {
      modalElement.addEventListener('touchstart', handleTouchStart);
      modalElement.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener('touchstart', handleTouchStart);
        modalElement.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, []);

  // Defensive checks
  if (
    !images ||
    !titles ||
    images.length === 0 ||
    titles.length === 0 ||
    index < 0 ||
    index >= images.length ||
    index >= titles.length
  ) {
    console.error("Invalid images or titles data for MenuModal");
    return null; // Prevent rendering if data is invalid
  }

  // Safe fallback for title in case it is undefined
  const currentTitle = titles[index] || 'No title available';

  return (
    <div
      className="menu-modal-overlay"
      id="menu-modal-overlay"
      onClick={onClose}
      role="dialog"
      aria-modal="true"
    >
      <div
        className="menu-modal-content"
        onClick={(e) => e.stopPropagation()}
        role="document"
      >
        <button className="close-button" onClick={onClose} aria-label="Close">
          <FaTimes />
        </button>

        {/* Display the name above the image */}
        <div className="menu-item-title">{currentTitle}</div>

        <img src={images[index]} alt={`Menu ${index + 1}`} className="menu-modal-image" />

        {images.length > 1 && (
          <>
            <button className="nav-button left" onClick={showPrevious} aria-label="Previous">
              <FaChevronLeft />
            </button>
            <button className="nav-button right" onClick={showNext} aria-label="Next">
              <FaChevronRight />
            </button>
          </>
        )}
        <div className="image-counter">
          {index + 1} / {images.length}
        </div>
      </div>
    </div>
  );
};

export default MenuModal;
